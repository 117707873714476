import { useSelector } from 'react-redux';
import { includes, some, values } from 'lodash';
import { useCallback, useMemo } from 'react';

import roles from 'common/constants/roles';

const hasAuthorizedRoles = (userRoles) => (allowedRoles) =>
  some(userRoles, (role) => includes(allowedRoles, role));

const useRoles = () => {
  const userRoles = useSelector(({ auth }) => auth.currentTenantRoles);

  const hasAccess = useCallback(
    (allowedRoles) => hasAuthorizedRoles(userRoles)(allowedRoles),
    [userRoles]
  );

  // https://smartscripts-eng.atlassian.net/wiki/spaces/POM/pages/175865897/RBAC
  return useMemo(
    () => ({
      hasAuthorizedRoles: hasAuthorizedRoles(userRoles),
      // Patients List
      viewPatientsList: hasAccess(values(roles)),
      createNewPatient: hasAccess([roles.admin, roles.ccc]),
      // Patient details
      viewPatientDetails: hasAccess(values(roles)),
      editPatientProfile: hasAccess(values(roles)),
      addMedication: hasAccess(values(roles)),
      updateMedication: hasAccess(values(roles)),
      deleteMedication: hasAccess(values(roles)),
      addMedicalHistory: hasAccess(values(roles)),
      updateMedicalHistory: hasAccess(values(roles)),
      deleteMedicalHistory: hasAccess(values(roles)),
      addProvider: hasAccess(values(roles)),
      updateProvider: hasAccess(values(roles)),
      deleteProvider: hasAccess(values(roles)),
      addGuarantor: hasAccess(values(roles)),
      updateGuarantor: hasAccess(values(roles)),
      deleteGuarantor: hasAccess(values(roles)),
      addInsurance: hasAccess(values(roles)),
      updateInsurance: hasAccess(values(roles)),
      deleteInsurance: hasAccess(values(roles)),
      addPharmacy: hasAccess(values(roles)),
      updatePharmacy: hasAccess(values(roles)),
      deletePharmacy: hasAccess(values(roles)),
      updateAuthorizations: hasAccess(values(roles)),
      addComments: hasAccess(values(roles)),
      deleteComments: hasAccess([roles.admin]),
      onboardPatient: hasAccess([roles.admin, roles.ccc]),
      addPartner: hasAccess(values(roles)),
      updatePartnerIdentityValue: hasAccess(values(roles)),
      deletePartner: hasAccess(values(roles)),
      listPatientPrescriptions: hasAccess(values(roles)),
      runSubmitTestClaims: hasAccess([roles.admin, roles.billingUser]),
      eligibilityCheck: hasAccess([roles.admin, roles.pharmTech]),
      prescriptionVerification: hasAccess([roles.admin, roles.pharmacist]),
      addPatientAddress: hasAccess(values(roles)),
      deletePatientAddress: hasAccess(values(roles)),
      updatePatientAddress: hasAccess(values(roles)),
      listPatientOrders: hasAccess(values(roles)),
      reviewLineItem: hasAccess([roles.admin, roles.pharmacist, roles.pharmTech]),
      eCommPrescriptionEligibility: hasAccess([roles.admin, roles.pharmTech]),
      addPatientNote: hasAccess(values(roles)),
      listLineItemNotes: hasAccess(values(roles)),
      noteAction: hasAccess(values(roles)),
      manageProductFulfillmentStatus: hasAccess([
        roles.pharmacyProdTech,
        roles.admin,
        roles.pharmTech,
      ]),
      verifyOrder: hasAccess([roles.pharmacist, roles.admin]),
      cancelOrder: hasAccess([
        roles.pharmacist,
        roles.admin,
        roles.pharmTech,
        roles.pharmacyProdTech,
      ]),
      createOrderShipment: hasAccess([roles.shippingUser, roles.admin]),
      getPatientUsers: hasAccess([roles.admin, roles.ccc]),
      triggerPasswordResetForPEUser: hasAccess([roles.admin, roles.ccc]),
      generateBulkLabels: hasAccess([
        roles.pharmacyProdTech,
        roles.admin,
        roles.pharmacist,
        roles.pharmTech,
        roles.shippingUser,
      ]),
      markLineItemsPrinted: hasAccess([
        roles.admin,
        roles.pharmTech,
        roles.pharmacist,
        roles.pharmacyProdTech,
        roles.shippingUser,
      ]),
      editPatientPrescription: hasAccess([
        roles.admin,
        roles.pharmTech,
        roles.pharmacist,
        roles.ccc,
      ]),
      editPrescriptionAutofill: hasAccess([roles.admin, roles.pharmTech, roles.ccc]),
      assertQs1RxNumber: hasAccess([roles.admin, roles.pharmacist]),
      entryComplete: hasAccess([roles.admin, roles.pharmTech]),
      markOrderForExternalProcessing: hasAccess([roles.admin, roles.pharmacist, roles.pharmTech]),
      updateOrderAddress: hasAccess(values(roles)),
      reverifyOrder: hasAccess([roles.pharmacist, roles.admin]),
      editOrderShipment: hasAccess([
        roles.admin,
        roles.pharmTech,
        roles.pharmacist,
        roles.shippingUser,
        roles.pharmacyProdTech,
      ]),
      needsReview: hasAccess([roles.admin, roles.pharmacist, roles.pharmTech]),
      // WorkQueue
      viewWorkQueue: hasAccess(values(roles)),
      viewPatientQualificationQueue: hasAccess([roles.admin, roles.ccc, roles.billingUser]),
      viewInClaimEligibility: hasAccess([roles.admin, roles.pharmTech, roles.pharmacist]),
      reviewInClaimEligibility: hasAccess([roles.admin, roles.pharmacist, roles.pharmTech]),
      listOrderLineItems: hasAccess([roles.admin, roles.billingUser, roles.pharmacist]),
      listBulkLabels: hasAccess([
        roles.pharmacyProdTech,
        roles.admin,
        roles.pharmacist,
        roles.ccc,
        roles.pharmTech,
      ]),
      listOrdersForProductFulfillment: hasAccess([roles.pharmacyProdTech, roles.admin]),
      listOrders: hasAccess([roles.admin, roles.pharmacist]),
      listReadyToShipOrders: hasAccess([roles.shippingUser, roles.admin]),
      reviewAdjudication: hasAccess([roles.admin, roles.billingUser]),
      profileIncomplete: hasAccess([roles.pharmTech, roles.ccc, roles.admin]),
      completeProfile: hasAccess([roles.pharmTech, roles.ccc, roles.admin]),
      prescriptionReview: hasAccess([roles.pharmacist, roles.admin, roles.pharmTech]),
      eVisitRequests: hasAccess([roles.admin, roles.ccc]),
      // Events
      viewEvents: hasAccess([roles.admin]),
      // Payment transactions
      viewPaymentTransactions: hasAccess([roles.admin, roles.billingUser, roles.ccc]),
      // Admin Center
      viewAdminCenter: hasAccess([
        roles.admin,
        roles.pharmacist,
        roles.pharmTech,
        roles.pharmacyProdTech,
      ]),
      viewDrug: hasAccess([roles.admin, roles.pharmacist]),
      viewSystem: hasAccess([
        roles.admin,
        roles.pharmacist,
        roles.pharmTech,
        roles.pharmacyProdTech,
      ]),
      viewDrugManagement: hasAccess([roles.admin, roles.pharmacist]),
      viewRequestStatus: hasAccess([
        roles.admin,
        roles.pharmacist,
        roles.pharmTech,
        roles.pharmacyProdTech,
      ]),
      viewSigManagement: hasAccess([roles.admin, roles.pharmacist]),
      // Orders list
      viewOrdersList: hasAccess(Object.values(roles)),
    }),
    [userRoles, hasAccess]
  );
};

export default useRoles;
