import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { compact, isEmpty, values } from 'lodash';

import { filterEVisitRequests } from 'store/thunks/workQueueThunks';

const EVisitRequestsFilterStatusBar = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ workQueue }) => workQueue.eVisitRequests?.filter);

  const removeFilter = (filterName) => () =>
    dispatch(filterEVisitRequests({ filter: { ...filter, [filterName]: undefined } }));

  const removeAllFilter = () => dispatch(filterEVisitRequests({ filter: {} }));

  return (
    <Stack direction='row' spacing={1}>
      {filter.requestId && (
        <Chip
          label={`Request Id = ${filter.requestId}`}
          variant='contained'
          onDelete={removeFilter('requestId')}
        />
      )}

      {filter.requestReferenceId && (
        <Chip
          label={`Request Reference Id = ${filter.requestReferenceId}`}
          variant='contained'
          onDelete={removeFilter('requestReferenceId')}
        />
      )}

      {filter.patientName && (
        <Chip
          label={`Patient Name = ${filter.patientName}`}
          variant='contained'
          onDelete={removeFilter('patientName')}
        />
      )}

      {filter.requestedDate && (
        <Chip
          label={`Requested Date = ${format(new Date(filter.requestedDate), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter('requestedDate')}
        />
      )}

      {!isEmpty(compact(values(filter))) && <Button onClick={removeAllFilter}>Remove All</Button>}
    </Stack>
  );
};

export default EVisitRequestsFilterStatusBar;
