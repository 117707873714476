import { createSlice } from '@reduxjs/toolkit';
import { assign, find } from 'lodash';

import RequestStatus from 'enums/request.enum';

export const initialState = {
  preset: '',
  patientQualification: {
    currentPage: 1,
    pages: {},
    count: 0,
    filter: {
      status: 'ONBOARDED',
      gender: undefined,
      createdAfter: undefined,
      dob: undefined,
      name: undefined,
    },
    sortBy: {
      sortKey: 'onBoardedDate',
      sortOrder: 'ASC',
    },
  },
  inClaimEligibility: {
    currentPage: 1,
    pages: {},
    count: 0,
    filter: {
      patientName: undefined,
      partnerName: undefined,
      requestedDate: undefined,
      needsByDate: undefined,
      requestStatus: RequestStatus.OPEN,
    },
    sortBy: {
      sortKey: 'needsByDate',
      sortOrder: 'ASC',
    },
  },
  reviewInClaimEligibility: {
    currentPage: 1,
    pages: {},
    count: 0,
    filter: {
      patientName: undefined,
      partnerName: undefined,
      requestedDate: undefined,
      needsByDate: undefined,
      taggedForReview: true,
    },
    sortBy: {
      sortKey: 'needsByDate',
      sortOrder: 'ASC',
    },
  },
  orderLineItems: {
    currentPage: 1,
    pages: {},
    count: 0,
    filter: {
      patientName: undefined,
      partnerName: undefined,
      requestedDate: undefined,
      needsByDate: undefined,
      orderStatus: undefined,
    },
    sortBy: {
      sortKey: 'needsByDate',
      sortOrder: 'ASC',
    },
  },
  bulkLabels: {
    currentPage: 1,
    pages: {},
    count: 0,
    filter: {
      patientName: undefined,
      medicationName: undefined,
      lastPrintedDate: undefined,
      needsByDate: undefined,
      isPrinted: false,
      taggedForReview: false,
    },
    sortBy: {
      sortKey: 'needsByDate',
      sortOrder: 'ASC',
    },
  },
  productFulfillment: {
    currentPage: 1,
    pages: {},
    count: 0,
    filter: {
      orderId: undefined,
      patientName: undefined,
      mpi: undefined,
      needsByDate: undefined,
    },
    sortBy: {
      sortKey: 'needsByDate',
      sortOrder: 'ASC',
    },
  },
  orderVerification: {
    currentPage: 1,
    pages: {},
    count: 0,
    filter: {
      patientName: undefined,
      needsByDate: undefined,
      orderId: undefined,
      orderStatus: ['ORDER_VERIFICATION', 'ORDER_RE_VERIFICATION'],
    },
    sortBy: {
      sortKey: 'needsByDate',
      sortOrder: 'ASC',
    },
  },
  readyToShip: {
    currentPage: 1,
    pages: {},
    count: 0,
    filter: {
      patientName: undefined,
      needsByDate: undefined,
      orderId: undefined,
      orderStatus: ['ORDER_VERIFIED', 'HOLD'],
    },
    sortBy: {
      sortKey: 'needsByDate',
      sortOrder: 'ASC',
    },
  },
  reviewAdjudication: {
    currentPage: 1,
    pages: {},
    count: 0,
    filter: {
      patientName: undefined,
      adjudicatedTime: undefined,
      qs1Id: undefined,
      rxNumber: undefined,
      adjudicationStatus: undefined,
    },
    sortBy: {
      sortKey: 'adjudicatedTime',
      sortOrder: 'ASC',
    },
  },
  profileIncomplete: {
    currentPage: 1,
    pages: {},
    count: 0,
    filter: {
      name: undefined,
      dob: undefined,
      createdAfter: undefined,
    },
    sortBy: {
      sortKey: 'createdTimestamp',
      sortOrder: 'ASC',
    },
  },
  prescriptionReview: {
    currentPage: 1,
    pages: {},
    count: 0,
    filter: {
      drugName: undefined,
      requestedDate: undefined,
      reviewStatus: undefined,
    },
    sortBy: {
      sortKey: 'requestedDate',
      sortOrder: 'ASC',
    },
  },
  eVisitRequests: {
    currentPage: 1,
    pages: {},
    count: 0,
    filter: {
      bundleLabel: undefined,
      requestedDate: undefined,
      requestId: undefined,
      requestReferenceId: undefined,
    },
    sortBy: {
      sortKey: 'requestedDate',
      sortOrder: 'ASC',
    },
  },
};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const workQueueSlice = createSlice({
  name: 'workQueue',
  initialState,
  reducers: {
    loadSelectedWorkQueues(state, action) {
      state.preset = action.payload;
    },
    loadWorkQueueToPage(state, action) {
      const { preset, page, items, count } = action.payload;

      state[preset].count = count;
      state[preset].pages[page] = items;
    },
    updateCurrentPage(state, action) {
      const { preset, page } = action.payload;

      state[preset].currentPage = page;
    },
    updateSortBy(state, action) {
      const { preset, sortBy } = action.payload;

      state[preset].pages = {}; // Cached data no longer valid, this is to reset it.
      state[preset].count = 0;
      state[preset].sortBy = sortBy;
    },
    updateFilter(state, action) {
      const { preset, filter } = action.payload;

      state[preset].pages = {}; // Cached data no longer valid, this is to reset it.
      state[preset].count = 0;
      state[preset].filter = filter;
    },
    loadFailedAdjudicationNotes(state, action) {
      const { notes, contextId, hasPinnedNotes } = action.payload;

      const pageIndex = state.reviewAdjudication.currentPage;
      const failedPrescription = find(state.reviewAdjudication.pages[pageIndex], {
        prescriptionId: contextId,
      });
      assign(failedPrescription, { notes, hasPinnedNotes });
    },
    loadReviewPrescriptionNotes(state, action) {
      const { notes, contextId } = action.payload;
      const pageIndex = state.prescriptionReview.currentPage;
      const reviewPrescription = find(state.prescriptionReview.pages[pageIndex], {
        pomPrescriptionId: contextId,
      });

      assign(reviewPrescription, { notes });
    },
  },
});

export const workQueueActions = workQueueSlice.actions;
export default workQueueSlice.reducer;
